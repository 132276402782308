<!--<svelte:options tag="gantt-chart" /> -->
<svelte:options accessors={true} />

<script>
  import GanttTable from "./lib/GanttTable.svelte";
  import GanttNavi from "./lib/GanttNavi.svelte";
  import { apiStoreGET } from "./api";
  import { primaryScale } from './stores';
  import { windowStartKm } from './stores';
  import { windowEndKm } from './stores';
  import { setContext } from 'svelte';
  import { getContext } from 'svelte';

  const objecturl = getContext("objecturl");
  const chartTaxonomy = getContext("charttaxonomy");
  const trackStartKm = getContext("trackStartKm");
  const trackEndKm = getContext("trackEndKm");

  let searchurl = new URL(objecturl);
  // searchurl.search = new URLSearchParams("sort_on=start&sort_order=ascending&metadata_fields=start&metadata_fields=end&metadata_fields=Subject&metadata_fields=UID&metadata_fields=termin_art").toString();
  let searchParams = new URLSearchParams(searchurl.search);
  searchParams.set("sort_on", "start")
  searchParams.set("sort_order", "ascending")
  searchParams.append("metadata_fields", "start_m")
  searchParams.append("metadata_fields", "end_m")
  searchParams.append("metadata_fields", "Subject")
  searchParams.append("metadata_fields", "UID")
  searchParams.append("metadata_fields", "facility")
  searchurl.search = searchParams.toString();

  let trackItemsResponse = apiStoreGET(searchurl.search);
  $windowStartKm = 0;

  const chartTaxonomyMap = new Map([
    ["gewerk", "facility"],
  ])
  let chartTaxonomyParts = chartTaxonomy.split(".")
  let categoryField = chartTaxonomyParts[chartTaxonomyParts.length - 1];
  categoryField = chartTaxonomyMap.get(categoryField) || categoryField;
  setContext("categoryField", categoryField)

  $: {
    $windowStartKm;
    $primaryScale;
    trackItemsResponse = apiStoreGET(searchurl.search);
  }

  function previous(e) {
    $windowStartKm = $windowStartKm - $primaryScale;
    if($windowStartKm < trackStartKm){ $windowStartKm = trackStartKm}
  }

  function next(e) {
    $windowStartKm = $windowStartKm + $primaryScale;
    if($windowStartKm > (trackEndKm - $primaryScale)){
      $windowStartKm = trackEndKm - $primaryScale;
    }
  }

  setContext("previous", previous)
  setContext("next", next)
</script>

  {#await $trackItemsResponse}
    loading ...
  {:then data}
    <div class="Gantt">
      <div class="Week">
        <GanttNavi
          gantttitle="Track View"
          />
        <GanttTable
          items={data.items}
          { chartTaxonomy }
        />
      </div>
    </div>
  {/await}

<style>
</style>
